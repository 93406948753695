.main-navbar {
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 30;
    width: 100%;
    border-bottom: 1px solid #EDEDED;
    background: #212121;
    backdrop-filter: blur(12px);
    padding: 16px 0px;

    ul {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .nav-item {
            .nav-link {
                color: #FFF;
                font-size: 16px;
                font-weight: 400;
                line-height: 100%;
                padding: 0;
                padding-right: 40px;
                transition: 0.3s linear;

                &:hover {
                    font-weight: 700;
                }
            }
        }
    }

    .connect-btn {
        border-radius: 12px;
        border: 1px solid #E6E6E6;
        background: #FFF;
        box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
        display: flex;
        padding: 10px 15px;
        justify-content: center;
        align-items: center;
        color: #000;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.015px;
    }

    .dissconnect-btn {
        border-radius: 12px;
        background: #FE0000;
        box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
        display: flex;
        padding: 10px 28px;
        justify-content: center;
        align-items: center;
        color: #FFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.015px;
        border: none;
    }
}

@media (max-width:600px) {
    .offcanvas {
        width: 100% !important;
    }

    .main-navbar ul {
        position: static;
        transform: unset;
    }

    .main-navbar ul .nav-item .nav-link {
        padding: 15px 0;
        text-align: center;
    }

    .main-navbar .connect-btn {
        display: block;
        margin: 0 auto;
    }

    .navbar-collapse {
        border-radius: 15px;
        border: 1px solid #161516;
        background: rgba(17, 19, 22, 0.30);
        backdrop-filter: blur(3px);
        margin-top: 25px;
        padding: 15px;
    }

    .connectwallet-modal .modal-body .twice-btns button {
        padding: 30px 0;
    }
}


.offcanvas {
    background: #FFF !important;

    .offcanvas-header {
        padding: 16px 15px;
        border-bottom: 1px solid #EDEDED;
        background: #212121;
        backdrop-filter: blur(12px);

        .btn-close {
            background: url("../../../assets/close-circle-new.svg") !important;
            background-size: contain !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            border: none !important;
            opacity: unset;
            padding: 0;
            width: 26px;
            height: 26px;
            margin: 0;
            box-shadow: none !important;
        }
    }

    .offcanvas-body {
        padding: 34px 0px 0px;

        a {
            padding: 20px 25px;
            color: #080808;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            display: block;
            border-left: 6px solid transparent;

            &:hover {
                border-left: 6px solid #4CEAD7;
                background: #F4F4F4;
            }

            &:focus {
                border-left: 6px solid #4CEAD7;
                background: #F4F4F4;
            }

            &:active {
                border-left: 6px solid #4CEAD7;
                background: #F4F4F4;
            }
        }

        .btnss {
            padding: 34px 20px;

            .connect-btn {
                border-radius: 12px;
                border: 1px solid #E6E6E6;
                background: #FFF;
                box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
                display: flex;
                padding: 17px;
                justify-content: center;
                align-items: center;
                width: 100%;
                color: #000;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -0.015px;
            }

            .dissconnect-btn {
                border-radius: 12px;
                background: #FE0000;
                box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
                border: none;
                display: flex;
                padding: 17px;
                justify-content: center;
                align-items: center;
                width: 100%;
                color: #FFF;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -0.015px;
            }
        }
    }
}

.payyy {
    width: 50px !important;
    width: 50px !important;
    border-radius: 50% !important;
}