.claim {
    padding-top: 156px;
    padding-bottom: 100px;

    .claim-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        h6 {
            color: #000;
            font-size: 22px;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: 0.44px;
            text-transform: uppercase;
        }

        .claimallbtn {
            border-radius: 12px;
            border: 1px solid #E6E6E6;
            background: #FFF;
            box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            display: flex;
            padding: 15px 35px;
            align-items: center;
            justify-content: center;
        }

        .btn-claim {
            border-radius: 12px;
            border: 1px solid #E6E6E6;
            background: #FFF;
            box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            display: flex;
            padding: 15px 35px;
            align-items: center;
            justify-content: center;
        }


    }

    .bottom-table {
        .table-responsive {
            border-radius: 12px;
            border: 1px solid #E6E6E6;
            background: #FFF;
            backdrop-filter: blur(3px);
            width: 100%;

            table {
                width: 100%;
                margin: 0px;

                th {
                    border: none;
                    padding: 28px 30px;
                    color: #000;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0.28px;
                    text-transform: uppercase;
                    vertical-align: middle;
                    white-space: nowrap;
                    background-color: transparent;
                }

                td {
                    color: #000;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 100%;
                    padding: 19px 30px;
                    vertical-align: middle;
                    border-top: 1px solid #E6E6E6;
                    white-space: nowrap;
                    background-color: transparent;
                }
            }
        }

        .btn-claim {
            display: flex;
            padding: 15px 35px;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            background: #4CEAD7;
            box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
            border: none;
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;

            &.disable {
                background: #F8F8F8 !important;
                box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.02) inset !important;
                border: none !important;
            }

            &.claimed {
                background: #F8F8F8 !important;
                box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.02) inset !important;
                border: none !important;
            }

        }

    }

    .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 25px;

        .left-f {
            h6 {
                color: #8C8C8C;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
            }
        }

        .right-f {
            .page-link {
                border: none;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #8C8C8C;
                background-color: var(--login-bg);

                &.active {
                    border-radius: 5px !important;
                    background: #000 !important;
                    box-shadow: 0px -4px 0px 0px rgba(255, 255, 255, 0.20) inset !important;
                    color: #FFF !important;
                }

            }


            .page-item:first-child .page-link {
                color: #8C8C8C;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;

                svg path {
                    fill: #000;
                    stroke: #000;
                }
            }

            .page-item.active .page-link {
                border-radius: 5px;
                background: #000;
                box-shadow: 0px -4px 0px 0px rgba(255, 255, 255, 0.20) inset;
                color: #FFF;
            }

            .page-link:focus {
                box-shadow: none !important;
            }
        }
    }
}

.custom-container {
    max-width: 1220px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

@media (max-width:600px) {
    .claim .bottom-table .table-responsive {
        display: none !important;
    }

    .claim .claim-heading .btn-claim {
        width: 100%;
    }

    .claim .claim-heading .claimallbtn {
        width: 100%;
    }

    .claim .claim-heading {
        flex-direction: column;
        gap: 30px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .accmblview {
        display: block !important;
        border-radius: 12px;
        border: 1px solid #E6E6E6;
        background: #FFF;

        .accmblviewhead {
            color: #000;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.28px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            padding: 25px 28px;

            .arrowsacc {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        .accordion {
            border: none !important;
            background: transparent !important;
            box-shadow: none !important;
            outline: none !important;
            border-radius: none !important;

            .accordion-button::after {
                background: url("../../assets/accarrowdown.svg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 8.571px;
                height: 5.143px;
            }

            .accordion-item {
                border: none !important;
                background: transparent !important;
                box-shadow: none !important;
                outline: none !important;
                border-radius: none !important;

                .accordion-header {
                    border: none !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;

                    .accordion-button {
                        border-top: 1px solid #E6E6E6;
                        border-bottom: 1px solid #E6E6E6;
                        background: var(--login-bg);
                        padding: 22px 25px;

                        &:focus {
                            box-shadow: none;
                        }

                        .accheadermain {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 12px;

                            .accmainimage {
                                width: 30px;
                                height: 30px;
                                border-radius: 60px;
                                background: rgba(0, 0, 0, 0.04);
                                object-fit: cover;
                                object-position: center;
                                display: flex;
                                align-items: center;

                                .accinnerimg {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 60px;
                                    background: rgba(0, 0, 0, 0.04);
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }

                            .acctext {
                                color: #000;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 100%;
                            }
                        }
                    }
                }

                .accordion-body {
                    border: none !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;
                    padding: 0px 25px;

                    .acctexts {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 20px 0px;
                        border-bottom: 1px solid #C5C5C5;

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        .textleft {
                            color: #000;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%;
                            letter-spacing: 0.28px;
                            text-transform: uppercase;
                        }

                        .textright {
                            color: #000;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 21px;
                            letter-spacing: 0.28px;
                        }

                        .brdrbtn {
                            border: 1px solid #FFF;
                            background: #000;
                            box-shadow: 2px 2px 0px 0px #FFF;
                            display: flex;
                            padding: 7px 15px;
                            justify-content: center;
                            align-items: center;
                            color: #FFF;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }

    .accmblview .accordion .accordion-item .accordion-header .accordion-button {
        box-shadow: none !important;
    }

    .claim .footer-content .left-f h6 {
        display: none;
    }

    .claim .footer-content {
        flex-direction: column;
        gap: 15px;
        justify-content: center;
        align-items: center;

        // display: none;
        .right-f {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.noor {
    border: 1px solid #FF0083 !important;
    background-color: transparent !important;

}